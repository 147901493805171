<style scoped lang="scss">
@import './SectionStyle.scss';
</style>

<template>
  <section class="section section-benefits">
    <div class="background" style='width: 820px' v-if="!$device.isMobile">
      <picture class='db'>
        <source type="image/webp"
                srcset="https://s3-cdn.designerjourneys.com/static/images/home/benefits-background-1640.webp 2x, https://s3-cdn.designerjourneys.com/static/images/home/benefits-background.webp 1x">
        <img class="img-cover" alt="Image - Designer Journeys" src="https://s3-cdn.designerjourneys.com/static/images/home/benefits-background.png"
             srcset="https://s3-cdn.designerjourneys.com/static/images/home/benefits-background.png 1x, https://s3-cdn.designerjourneys.com/static/images/home/benefits-background-1640.png 2x">
      </picture>
    </div>

    <div class="container">
      <div class="flex-wrapper">
        <div class="left wp-50">
          <div class="left-background" v-if="$device.isMobile">
            <picture class='flex justify-center'>
              <source type="image/webp"
                      srcset="https://s3-cdn.designerjourneys.com/static/images/home/benefits-background-1100.webp">
              <img class="img-cover" alt="Image - Designer Journeys" src="https://s3-cdn.designerjourneys.com/static/images/home/benefits-background-1100.png">
            </picture>
          </div>

          <div class="left-image">
            <template v-if='$device.isDesktopOrTablet'>
              <picture class='db'>
                <source type="image/webp"
                        srcset="https://s3-cdn.designerjourneys.com/static/images/home/traveller-2x.webp 2x, https://s3-cdn.designerjourneys.com/static/images/home/traveller-1x.webp 1x">
                <img class="img-cover" alt="Image - Designer Journeys" src="https://s3-cdn.designerjourneys.com/static/images/home/traveller-1x.png"
                     srcset="https://s3-cdn.designerjourneys.com/static/images/home/traveller-1x.png 1x, https://s3-cdn.designerjourneys.com/static/images/home/traveller-2x.png 2x">
              </picture>
            </template>
            <template v-else>
              <picture class='db'>
                <source type="image/webp"
                        srcset="https://s3-cdn.designerjourneys.com/static/images/home/traveller-2x.webp">
                <img class="img-cover" alt="Image - Designer Journeys" src="https://s3-cdn.designerjourneys.com/static/images/home/traveller-2x.png">
              </picture>
            </template>
          </div>

          <div class='box-left-block'>
            <transition>
              <Carousel
                :wrap-around="true"
                :itemsToShow= 1
                :autoplay='12000'
                class='inner-wrapper'>
                <template #counter>
                </template>
                <Slide
                  class='carousel__item '

                >
                  <div class='left-block'>
                    <div class="left-block--avatar">
                      <template v-if='$device.isMobile'>
                        <picture class='db w-50 h-50'>
                          <source type="image/webp"
                                  srcset="https://s3-cdn.designerjourneys.com/static/images/general/Justin-2x.jpg 2x, https://s3-cdn.designerjourneys.com/static/images/general/Justin.jpg 1x">
                          <img class="img-cover br-p-50" alt="Image - Designer Journeys" src="https://s3-cdn.designerjourneys.com/static/images/general/Justin.jpg"
                               srcset="https://s3-cdn.designerjourneys.com/static/images/general/Justin.jpg 1x, https://s3-cdn.designerjourneys.com/static/images/general/Justin-2x.jpg 2x">
                        </picture>
                      </template>
                      <template v-else>
                        <picture class='db mb-w-40 mb-h-40'>
                          <source type="image/webp"
                                  srcset="https://s3-cdn.designerjourneys.com/static/images/general/Justin.jpg">
                          <img class="img-cover" alt="Image - Designer Journeys" src="https://s3-cdn.designerjourneys.com/static/images/general/Justin.jpg">
                        </picture>
                      </template>
                    </div>

                    <div class="left-block--description">
                      <p class='mb-4'>
                        It is great having local people who are able to tailor a trip to my individual needs. I love that I can chat to my consultant to make changes. The turnaround times from my brief were excellent.
                      </p>
                    </div>

                    <div class="left-block--author">
                      <span>Justin K.</span>
                    </div>
                  </div>
                </Slide>
                <Slide
                  class='carousel__item '
                >
                  <div class='left-block'>
                    <div class="left-block--avatar">
                      <template v-if='$device.isMobile'>
                        <picture class='db w-50 h-50'>
                          <source type="image/webp"
                                  srcset="https://s3-cdn.designerjourneys.com/static/images/general/Kat-2x.jpg 2x, https://s3-cdn.designerjourneys.com/static/images/general/Kat.jpg 1x">
                          <img class="img-cover br-p-50" alt="Image - Designer Journeys" src="https://s3-cdn.designerjourneys.com/static/images/general/Kat.jpg"
                               srcset="https://s3-cdn.designerjourneys.com/static/images/general/Kat.jpg 1x, https://s3-cdn.designerjourneys.com/static/images/general/Kat-2x.jpg 2x">
                        </picture>
                      </template>
                      <template v-else>
                        <picture class='db mb-w-40 mb-h-40'>
                          <source type="image/webp"
                                  srcset="https://s3-cdn.designerjourneys.com/static/images/general/Kat.jpg">
                          <img class="img-cover" alt="Image - Designer Journeys" src="https://s3-cdn.designerjourneys.com/static/images/general/Kat.jpg">
                        </picture>
                      </template>
                    </div>

                    <div class="left-block--description">
                      <p class='mb-4'>
                        Prompt and knowledgable of many areas and things to do. We felt like our requests were heard and they went above and beyond to provide a mixture of plans for our budget.
                      </p>
                    </div>

                    <div class="left-block--author">
                      <span>Kat F.</span>
                    </div>
                  </div>
                </Slide>
                <Slide
                  class='carousel__item '
                >
                  <div class='left-block'>
                    <div class="left-block--avatar">
                      <template v-if='$device.isMobile'>
                        <picture class='db w-50 h-50'>
                          <source type="image/webp"
                                  srcset="https://s3-cdn.designerjourneys.com/static/images/general/Hannah-2x.jpg 2x, https://s3-cdn.designerjourneys.com/static/images/general/Hannah.jpg 1x">
                          <img class="img-cover br-p-50" alt="Hannah" src="https://s3-cdn.designerjourneys.com/static/images/general/Hannah.jpg"
                               srcset="https://s3-cdn.designerjourneys.com/static/images/general/Hannah.jpg 1x, https://s3-cdn.designerjourneys.com/static/images/general/Hannah-2x.jpg 2x">
                        </picture>
                      </template>
                      <template v-else>
                        <picture class='db mb-w-40 mb-h-40'>
                          <source type="image/webp"
                                  srcset="https://s3-cdn.designerjourneys.com/static/images/general/Hannah.jpg">
                          <img class="img-cover" alt="Hannah" src="https://s3-cdn.designerjourneys.com/static/images/general/Hannah.jpg">
                        </picture>
                      </template>
                    </div>

                    <div class="left-block--description">
                      <p class='mb-4'>
                        Sangita was amazing. She was always available by phone if needed and we felt no hesitation to be in contact. She listened to exactly what we asked for and got it right. All the people we met that worked with her were very trustworthy and honest. I would recommend her and all the people she works with. It was a very personal and well managed trip.
                      </p>
                    </div>

                    <div class="left-block--author">
                      <span>Hannah J.</span>
                    </div>
                  </div>
                </Slide>
                <template #addons>
                  <Pagination />
                </template>
              </Carousel>
            </transition>
          </div>
        </div>

        <div class="right wp-50">
          <div class="right-small">DESIGN YOUR EXTRAORDINARY</div>

          <h2 class="right-title">Why Travel with Designer Journeys</h2>

          <div class="right-items">
            <div class="item">
              <div class="item-icon">
                <img
                  :src="`${ASSETS_CDN}/images/home/icons/icon-smile.svg`" width='28' height='28' alt='Smile icon'
                />
              </div>

              <div class="item-content">
                <h3 class="item-content--title">Your Trip. Your Version of Extraordinary.</h3>

                <div class="item-content--description">
                  <p class='mb-0'>
                    At Designer Journeys, we know that we all travel differently. That's why you decide where you travel, how you travel and your travel experiences when you design your trip with us.
                  </p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="item-icon">
                <img
                  :src="`${ASSETS_CDN}/images/home/icons/icon-conversation.svg`" width='28' height='28' alt='Message icon'

                />
              </div>

              <div class="item-content">
                <h3 class="item-content--title">
                  Connect and Design With an Expert.
                </h3>

                <div class="item-content--description">
                  <p class='mb-0'>
                    Our trusted Local Designers use their destination insight, local knowledge and travel planning expertise to build your extraordinary trip. Our dedicated Travel Concierge team will be there every step of the way to ensure your perfect trip becomes reality.
                  </p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="item-icon">
                <img
                  :src="`${ASSETS_CDN}/images/home/icons/icon-trust.svg`" width='28' height='28' alt='Trust icon'

                />
              </div>

              <div class="item-content">
                <h3 class="item-content--title">Book with Confidence. We are Trusted.</h3>

                <div class="item-content--description">
                  <p class='mb-0'>
                    Secure payment platforms, multiple accreditations, rigorous Local Designer selection process, and decades of travel expertise means you are in safe hands with Designer Journeys.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Slide from '~/components/ui/carousel/Slide'
import Pagination from '~/components/ui/carousel/Pagination'
import Carousel from '~/components/ui/carousel/Carousel'
import { PHONE } from '~/constant/common'
import {assetCDN} from '~/helper/index.js';

export default {
  data() {
    return {
      PHONE
    }
  },
  components: {Slide,Carousel,Pagination},
  setup() {

    const ASSETS_CDN = assetCDN()
    // const {isMobile} = useDevice()

    // const windowWidth = computed(() => store.state.generalStore.generalData.windowWidth)

    return { /*windowWidth, */ASSETS_CDN/*, isMobile*/ }
  }
}
</script>
<style lang='scss' scoped>
:deep(.carousel) {
  .carousel__item {
    padding: 50px;
    -webkit-align-items: flex-end;
    align-items: flex-end;

  }
  .carousel__pagination {
    position: absolute;
    display: flex;
    bottom: 74px;
    left: 80px;
    li span{
      display: inline-block;
      font-size: 0;
      width: 4px;
      height: 4px;
      background: #A4CBFA;
      opacity: 0.4;
      border-radius: 8px;
      margin-right: 2px;
      &.carousel__pagination-button--active {
        opacity: 1;
        width: 7px;
      }
    }
  }
}

</style>
<style lang="scss">
  .container .section-benefits .container {padding: 0}
</style>
